<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>General Settings</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters></v-row>
        </v-col>
      </v-row>
      <div>
        <v-card outlined class="mb-4">
          <v-list-item>
            <v-list-item-avatar color="accent" size="48">
              <img v-if="avatarExists" :src="avatar" :alt="user.full_name" />
              <span v-else class="white--text">{{ user.initials }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <div>
                <v-btn
                  depressed
                  color="success"
                  small
                  :loading="uploadAvatarForm.loading"
                  @click="uploadAvatarForm.dialog = true"
                  >Upload Avatar</v-btn
                >
                <v-btn
                  depressed
                  color="error"
                  small
                  :disabled="
                    !user.avatar ||
                      uploadAvatarForm.loading ||
                      deleteAvatarForm.loading
                  "
                  class="ml-4"
                  @click="deleteAvatarForm.dialog = true"
                  >Delete</v-btn
                >
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card outlined>
          <v-form @submit.prevent="updateInformation" method="post">
            <v-card-title>User Information</v-card-title>
            <v-card-text>
              <v-text-field
                label="Email"
                outlined
                v-model="userInfoForm.email"
                :error="userInfoForm.errors.hasOwnProperty('email')"
                :error-messages="userInfoForm.errors['email']"
              ></v-text-field>
              <v-text-field
                label="First Name"
                outlined
                v-model="userInfoForm.firstName"
                :error="userInfoForm.errors.hasOwnProperty('firstName')"
                :error-messages="userInfoForm.errors['firstName']"
              ></v-text-field>
              <v-text-field
                label="Last Name"
                outlined
                v-model="userInfoForm.lastName"
                :error="userInfoForm.errors.hasOwnProperty('lastName')"
                :error-messages="userInfoForm.errors['lastName']"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="accent"
                type="submit"
                :loading="userInfoForm.loading"
                >Save</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </div>
    </v-container>
    <v-dialog v-model="uploadAvatarForm.dialog" max-width="400px">
      <v-card>
        <v-form @submit.prevent="uploadAvatar" method="post">
          <v-card-title>
            <span class="headline">Upload Avatar</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-file-input
              v-model="uploadAvatarForm.file"
              :rules="uploadAvatarForm.rules"
              accept="image/png, image/jpeg"
              placeholder="Pick an avatar"
              prepend-icon="mdi-account-cowboy-hat"
              label="Avatar"
            ></v-file-input>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="uploadAvatarForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              depressed
              type="submit"
              :loading="uploadAvatarForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteAvatarForm.dialog" persistent max-width="290px">
      <v-card>
        <v-card-title class="headline">Delete Your Avatar</v-card-title>
        <v-card-text>Are you sure you want to delete your avatar?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            depressed
            @click="deleteAvatarForm.dialog = false"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteAvatarForm.loading"
            @click="deleteAvatar()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.open" :timeout="snackbar.timeout">{{
      snackbar.text
    }}</v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Account Settings",
          disabled: true,
        },
        {
          text: "General",
          disabled: true,
        },
      ],
      uploadAvatarForm: {
        dialog: false,
        file: null,
        loading: false,
        rules: [
          value =>
            !value ||
            value.size < 2000000 ||
            "Avatar size should be less than 2MB!",
        ],
      },
      deleteAvatarForm: {
        dialog: false,
        loading: false,
        error: false,
      },
      userInfoForm: {
        email: null,
        firstName: null,
        lastName: null,
        loading: false,
        errors: {},
      },
      snackbar: {
        open: false,
        text: "",
        timeout: 4000,
      },
    };
  },

  computed: {
    user() {
      const user = this.$store.getters.user;
      this.setupUser(user);
      return user;
    },
    avatarExists() {
      return this.$store.getters.userAvatar ? true : false;
    },
    avatar() {
      return `${process.env.VUE_APP_ACCOUNTS_URL}/storage/${this.$store.getters.userAvatar}`;
    },
  },

  methods: {
    uploadAvatar: function() {
      const avatar = this.uploadAvatarForm.file;
      this.uploadAvatarForm.loading = true;

      this.$store
        .dispatch("accountSettings/uploadAvatar", {
          avatar,
        })
        .then(() => {
          this.uploadAvatarForm.dialog = false;
          this.uploadAvatarForm.file = null;
          this.uploadAvatarForm.loading = false;
        })
        .catch(() => {
          this.uploadAvatarForm.loading = false;
        });
    },
    deleteAvatar: function() {
      this.deleteAvatarForm.loading = true;
      this.deleteAvatarForm.error = false;

      this.$store
        .dispatch("accountSettings/deleteAvatar")
        .then(() => {
          this.deleteAvatarForm.loading = false;
          this.deleteAvatarForm.dialog = false;
        })
        .catch(() => {
          this.deleteAvatarForm.loading = false;
          this.deleteAvatarForm.error = true;
        });
    },
    setupUser: function(user) {
      this.userInfoForm.email = user.email;
      this.userInfoForm.firstName = user.first_name;
      this.userInfoForm.lastName = user.last_name;
    },
    updateInformation: function() {
      const email = this.userInfoForm.email;
      const firstName = this.userInfoForm.firstName;
      const lastName = this.userInfoForm.lastName;
      this.userInfoForm.loading = true;
      this.userInfoForm.errors = {};

      this.$store
        .dispatch("accountSettings/updateInformation", {
          email,
          firstName,
          lastName,
        })
        .then(() => {
          this.userInfoForm.loading = false;
          this.snackbar.text = "Your information has been updated 👍";
          this.snackbar.open = true;
        })
        .catch(err => {
          this.userInfoForm.errors = err.response.data.errors;
          this.userInfoForm.loading = false;
        });
    },
  },
};
</script>
